export const cardsExceptSelf = (cards, curCard) => {
  let count = 0
  // 排除自己，最多四張卡片
  const cardsExceptSelf = cards.nodes.filter(item => {
    if (item.slug !== curCard.slug && count < 4) {
      count++
      return true
    }
  })

  return cardsExceptSelf
}
