import React from "react"
import PropTypes from "prop-types"
import Layout from "../components/layout"
import Consult from "../components/Consult"
import { useLandingPageData } from "../data/useLandingPageData"
import Breadcrumb from "../components/Breadcrumb"
import { graphql } from "gatsby"
import Sidebar from "../components/FindCoursePage/Sidebar"
import TeacherItem from "../components/FindTeacherPage/TeacherItem"
import TeacherCard from "../components/Card/TeacherCard"
import { GatsbyImage } from "gatsby-plugin-image"
import rehypeRaw from "rehype-raw"
import ReactMarkdown from "react-markdown"
import Button from "../components/Button"
import PostItem from "../components/Post/PostItem"
import Navigation from "../components/Navigation"
import { useMetadata } from "../data/use-metadata"
import { GatsbySeo } from "gatsby-plugin-next-seo/src/meta/gatsby-seo"
import { useModalUpdate } from "../contexts/ModalContext"
import { cardsExceptSelf } from "../functions/cardsExceptSelf"
import TagToFilterPage from "../components/Tag/TagToFilterPage"
import { BlogPostJsonLd } from "gatsby-plugin-next-seo"

const TeacherIntro = ({ data, slug }) => {
  const {
    type,
    name,
    description: { description },
    image: { gatsbyImageData },
    topic,
    category,
  } = data

  const setIsModal = useModalUpdate()

  const tags = []

  topic.forEach(i => tags.push(i))
  category.forEach(i => tags.push(i))

  const tagList = tags.map((tag, idx) => {
    const pathName = `/${slug}?type=${tag.sys.contentType.sys.id}&tag=${tag.name}`
    const tagStyle = idx === tags.length - 1 ? "mr-0" : "mr-3.5"

    return (
      <TagToFilterPage
        tagStyle={tagStyle}
        key={`key-${idx}`}
        slug={pathName}
        name={tag.name}
      />
    )
  })

  return (
    <div className="p-6 bg-neutral-50 sm:p-8 lg:p-12">
      <div className="flex flex-col items-center sm:flex-row sm:gap-8 sm:items-start">
        <div className="sm:flex sm:flex-col">
          <GatsbyImage
            image={gatsbyImageData}
            alt={name}
            className="h-48 w-48 z-0 object-cover rounded-full sm:flex-shrink-0 lg:h-60 lg:w-60"
          />
          <Button
            size="md"
            style="mt-8 hidden sm:inline-flex sm:self-center"
            isLink={false}
            onClick={setIsModal}
          >
            聯絡講師
          </Button>
        </div>
        <div className="w-full">
          <div className="flex flex-col items-center sm:flex-row sm:mb-4 sm:gap-8">
            <h1 className="mt-4 mb-2 text-3xl !leading-tight font-bold text-neutral-800 sm:text-5xl sm:mt-0 sm:mb-0">
              {name}
            </h1>
            <p className="mb-4 text-xl !leading-loose text-neutral-600 sm:text-3xl sm:mb-0">
              {type}
            </p>
          </div>
          <div className="border-t border-neutral-400 flex-grow" />
          <div className="my-2">{tagList}</div>
          <p className="!leading-normal text-neutral-600 sm:text-lg">
            {description}
          </p>
        </div>
        <Button style="mt-2.5 sm:hidden" isLink={false} onClick={setIsModal}>
          聯絡講師
        </Button>
      </div>
    </div>
  )
}

const TeacherTemplate = ({ data }) => {
  const { consultGatsbyImageData, consultTitle } = useLandingPageData()

  const {
    contentfulTeacher: teacher,
    popularTeachers,
    recommendedTeachers,
    teachers,
    posts,
  } = data

  // 取該講師寫過的文章，最多三篇
  const curTeacherPosts = (() => {
    if (posts.nodes.length) {
      const teacherPosts = posts.nodes.filter(post => {
        return post.author.name === teacher.name
      })

      const arr = []

      if (teacherPosts.length > 3) {
        for (let i = 0; i < 3; i++) {
          arr.push(teacherPosts[i])
        }
      }

      return teacherPosts.length > 3 ? arr : teacherPosts
    }
    return posts
  })()

  const slug = "teacher"

  const crumbs = [
    {
      name: "首頁",
      url: "/",
    },
    {
      name: "找講師",
      url: `/${slug}`,
    },
    {
      name: `${teacher.name}`,
      url: `/${slug}/${teacher.slug}`,
    },
  ]

  const { siteUrl } = useMetadata()
  const canonical = `${siteUrl}/${slug}/${teacher.slug}`

  // 精選區塊
  const recommendedTeachersItems = recommendedTeachers.nodes.map(teacher => (
    <TeacherItem key={teacher.id} teacher={teacher} />
  ))

  // 熱門區塊
  const popularTeachersItems = popularTeachers.nodes.map(teacher => (
    <TeacherItem key={teacher.id} teacher={teacher} />
  ))

  const postList = curTeacherPosts.map(post => {
    return <PostItem post={post} key={post.id} />
  })

  const teacherCardsExceptSelf = cardsExceptSelf(teachers, teacher)

  const teacherCards = teacherCardsExceptSelf.map((teacher, idx) => {
    const {
      slug,
      name,
      image: { gatsbyImageData },
      description: { description },
      type,
    } = teacher

    // 猜你會喜歡卡片顯示邏輯 > 手機三張 平板四張 電腦版三張
    const isLast =
      teacherCardsExceptSelf.length > 3 &&
      idx === teacherCardsExceptSelf.length - 1

    return (
      <TeacherCard
        key={teacher.id}
        slug={slug}
        name={name}
        gatsbyImageData={gatsbyImageData}
        type={type}
        description={description}
        isLast={isLast}
        isTeacherPage={true}
      />
    )
  })

  return (
    <Layout>
      <GatsbySeo
        title={teacher.name}
        description={teacher.description.description}
        canonical={canonical}
        openGraph={{
          url: canonical,
          title: teacher.name,
          images: [
            {
              url: `https:${teacher.image.file.url}`,
              width: 1200,
              height: 630,
              alt: teacher.name,
            },
          ],
        }}
      />
      <BlogPostJsonLd
        url={canonical}
        images={[`https:${teacher.image.file.url}`]}
        datePublished={teacher.createdAt}
        dateModified={teacher.updatedAt}
        authorName={teacher.name}
        description={teacher.description.description}
      />

      <div className="my-12 wrapper">
        <Breadcrumb crumbs={crumbs} />
      </div>

      <div className="wrapper">
        <div className="lg:grid lg:grid-cols-5 lg:gap-8">
          <div className="lg:col-span-4 lg:h-fit">
            {/* 卡片 markdown 講師觀點 */}
            <TeacherIntro data={teacher} slug={slug} />
            <div className="markdown inner-page lg:col-span-4">
              <ReactMarkdown rehypePlugins={[rehypeRaw]}>
                {teacher?.content?.content}
              </ReactMarkdown>
            </div>
            {curTeacherPosts.length ? (
              <>
                <h2 className="mt-12 text-2xl !leading-loose !tracking-wide font-bold text-neutral-800 sm:text-3xl">
                  講師觀點
                </h2>
                <ul className="divide-y">{postList}</ul>
                <div className="border-t border-t-neutral-300" />
                <div className="flex justify-start mt-6 -translate-x-3">
                  <Navigation slug="training-news" text="看更多文章" />
                </div>
              </>
            ) : null}
          </div>
          <Sidebar
            popularItems={popularTeachersItems}
            recommendedItems={recommendedTeachersItems}
            hasBorder={false}
          />
        </div>
      </div>

      <div className="wrapper my-24 lg:my-36">
        {teacherCardsExceptSelf.length ? (
          <div className="border-t-4 border-amber-400">
            <div className="flex justify-between items-center mb-8 mt-3.5 sm:mb-12">
              <h2 className="text-3xl !leading-normal tracking-wide font-bold text-neutral-800 lg:text-5xl">
                猜你會喜歡
              </h2>
              <Navigation slug={slug} text="全部講師" />
            </div>
            <ul className="grid grid-cols-1 gap-8 sm:grid-cols-2 lg:grid-cols-3">
              {teacherCards}
            </ul>
          </div>
        ) : null}
      </div>

      <Consult
        gatsbyImageData={consultGatsbyImageData}
        consultTitle={consultTitle}
      />
    </Layout>
  )
}

TeacherIntro.propTypes = {
  data: PropTypes.object,
  slug: PropTypes.string,
}

TeacherTemplate.propTypes = {
  data: PropTypes.object.isRequired,
}

export default TeacherTemplate

export const pageQuery = graphql`
  query ($slug: String!, $firstTopic: String!) {
    contentfulTeacher(slug: { eq: $slug }) {
      id
      slug
      type
      name
      updatedAt(formatString: "YYYY-MM-DD")
      createdAt(formatString: "YYYY-MM-DD")
      description {
        description
      }
      image {
        gatsbyImageData(
          width: 240
          height: 240
          placeholder: BLURRED
          quality: 100
        )
        file {
          url
        }
      }
      topic {
        id
        name
        contentful_id
        sys {
          contentType {
            sys {
              id
            }
          }
        }
      }
      category {
        id
        name
        contentful_id
        sys {
          contentType {
            sys {
              id
            }
          }
        }
      }
      content {
        content
      }
    }
    recommendedTeachers: allContentfulTeacher(
      limit: 3
      sort: { fields: updatedAt, order: DESC }
      filter: { isRecommended: { eq: true } }
    ) {
      nodes {
        id
        name
        type
        slug
        image {
          title
          gatsbyImageData
        }
      }
    }
    popularTeachers: allContentfulTeacher(
      limit: 3
      sort: { fields: updatedAt, order: DESC }
      filter: { isPopular: { eq: true } }
    ) {
      nodes {
        id
        name
        type
        slug
        image {
          title
          gatsbyImageData
        }
      }
    }
    teachers: allContentfulTeacher(
      filter: { topic: { elemMatch: { name: { eq: $firstTopic } } } }
      sort: { fields: updatedAt, order: DESC }
      limit: 5
    ) {
      nodes {
        id
        slug
        type
        name
        description {
          description
        }
        image {
          gatsbyImageData
        }
      }
    }
    posts: allContentfulPost(sort: { fields: updatedAt, order: DESC }) {
      nodes {
        id
        name
        updatedAt(formatString: "YYYY-MM-DD")
        slug
        topic {
          slug
        }
        author {
          ... on ContentfulExternalAuthor {
            id
            name
          }
          ... on ContentfulTeacher {
            id
            name
          }
        }
      }
    }
  }
`
