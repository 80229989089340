import React from "react"
import PropTypes from "prop-types"
import Link from "../Link"

const TagToFilterPage = ({ name, tagStyle, slug }) => (
  <Link
    to={slug}
    as="link"
    style={`ease-in-out duration-300 hover:bg-neutral-300 inline-block text-sm text-neutral-600 rounded bg-neutral-200 px-3 py-2 my-2 ${tagStyle}`}
  >
    {name}
  </Link>
)

TagToFilterPage.propTypes = {
  name: PropTypes.string,
  tagStyle: PropTypes.string,
  slug: PropTypes.string,
}

export default TagToFilterPage
